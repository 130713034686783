@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0
}

*,
:after,
:before {
    box-sizing: border-box
}

:root {
    --primary-color: #5b2e90;
    --primary-color-hover: #2c004b;
    --secondary-color: #ffb200;
    --secondary-color-hover: #ff9200;
    --success-color: #34ab56;
    --success-color-hover: #145d29;
    --danger-color: #e53a20;
    --danger-color-hover: #bc3025;
    --warning-color: #ffc107;
    --warning-color-hover: #e0a800;
    --info-color: #5c9bdc;
    --info-color-hover: #3b638c;
    --light-color: #ebebeb;
    --light-color-hover: #bdbdbd;
    --dark-color: #343a40;
    --dark-color-hover: #23272b;
    --gray-color: #ccc;
    --white-color: #fff;
    --navbar-a-color: #333;
    --navbar-color: var(--white-color);
    --widget-head-color: #424242;
    --widget-body-color: #333;
    --breadcrumb-color: transparent
}

a {
    color: var(--primary-color);
    text-decoration: none
}

p {
    margin-bottom: 1rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Dosis, sans-serif;
    font-weight: 700;
    margin-bottom: .5rem
}

h1 {
    font-size: 1.25rem
}

h2 {
    font-size: 1.125rem
}

h3 {
    font-size: 1rem
}

div,
label,
p,
span {
    font-family: 'dosis', Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem
}

.custom-checkbox .custom-control-label:before {
    border-radius: .25rem
}

.custom-control-label:before {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6
}

.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: .1rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: ""
}

.custom-control-label:after {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50% 50%
}

.custom-radio .custom-control-label:after,
.custom-radio .custom-control-label:before {
    border-radius: 50%
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label:before,
.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: var(--primary-color)
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-uppercase {
    text-transform: uppercase
}

.text-primary {
    color: var(--primary-color)
}

.text-secondary {
    color: var(--secondary-color)
}

.text-success {
    color: var(--success-color)
}

.text-danger {
    color: var(--danger-color)
}

.text-warning {
    color: var(--warning-color)
}

.text-info {
    color: var(--info-color)
}

.text-light {
    color: var(--light-color)
}

.text-dark {
    color: var(--dark-color)
}

.text-gray {
    color: var(--gray-color)
}

.btn-block {
    width: 100%
}

.alert {
    border: 1px solid transparent;
    padding: .75rem 1.25rem;
    position: relative
}

.alert-primary {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color-hover)
}

.alert-secondary {
    color: var(--white-color);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color-hover)
}

.alert-success {
    color: var(--white-color);
    background-color: var(--success-color);
    border-color: var(--success-color-hover)
}

.alert-danger {
    color: var(--white-color);
    background-color: var(--danger-color);
    border-color: var(--danger-color-hover)
}

.alert-warning {
    color: var(--white-color);
    background-color: var(--warning-color);
    border-color: var(--warning-color-hover)
}

.alert-info {
    color: var(--white-color);
    background-color: var(--info-color);
    border-color: var(--info-color-hover)
}

.alert-light {
    color: var(--dark-color-hover);
    background-color: var(--light-color);
    border-color: var(--light-color-hover)
}

.alert-dark {
    color: var(--white-color);
    background-color: var(--dark-color);
    border-color: var(--dark-color-hover)
}

.breadcrumb {
    font-family: 'dosis', Verdana, sans-serif;
    font-size: .8125rem;
    font-weight: 400;
    background: var(--breadcrumb-color)
}

.breadcrumb li a {
    color: #000
}

.breadcrumb li.active a,
.btn {
    font-weight: 700
}

.btn {
    display: inline-block;
    box-sizing: border-box;
    font-family: Dosis, sans-serif;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    border: 0;
    outline: 0;
    padding: .375rem .75rem
}

.btn-outline-danger,
.btn-outline-dark,
.btn-outline-info,
.btn-outline-light,
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-warning {
    border: 1px solid;
    background-color: transparent
}

.btn-primary {
    color: #fff;
    background-color: var(--primary-color)
}

.btn-secondary {
    color: #fff;
    background-color: var(--secondary-color)
}

.btn-success {
    color: #fff;
    background-color: var(--success-color)
}

.btn-danger {
    color: #fff;
    background-color: var(--danger-color)
}

.btn-warning {
    color: #333;
    background-color: var(--warning-color)
}

.btn-info {
    color: #fff;
    background-color: var(--info-color)
}

.btn-light {
    color: #333;
    background-color: var(--light-color)
}

.btn-dark {
    color: #fff;
    background-color: var(--dark-color)
}

.btn-link {
    color: #333;
    background-color: transparent
}

.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color)
}

.btn-outline-secondary {
    color: var(--secondary-color);
    border-color: var(--secondary-color)
}

.btn-outline-success {
    color: var(--success-color);
    border-color: var(--success-color)
}

.btn-outline-danger {
    color: var(--danger-color);
    border-color: var(--danger-color)
}

.btn-outline-warning {
    color: var(--warning-color);
    border-color: var(--warning-color)
}

.btn-outline-info {
    color: var(--info-color);
    border-color: var(--info-color)
}

.btn-outline-light {
    border-color: var(--light-color)
}

.btn-outline-dark {
    color: var(--dark-color);
    border-color: var(--dark-color)
}

.card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    background-color: #fff;
    background-clip: border-box
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

input {
    box-sizing: border-box;
    font-size: 1rem;
    font-family: 'dosis', sans-serif;
    font-weight: 300;
    border-radius: 0;
    margin: 0
}

.form-group {
    margin-bottom: 1.563rem
}

.form-control {
    display: block;
    height: 2.5rem;
    width: 100%;
    border: 1px solid var(--gray-color);
    padding: .375rem .75rem;
    border-radius: 0
}

.custom-control {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    position: relative
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.input-required:after {
    content: " *"
}

.navbar-lx {
    display: -ms-flexbox;
    display: flex;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    font-family: Dosis, sans-serif;
    font-weight: 400;
    position: relative;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
    background-color: var(--navbar-color)
}

.navbar-lx a {
    color: var(--navbar-a-color)
}

.navbar-lx .nav-link {
    display: block;
    padding: .5rem 1rem
}

.navbar-brand-lx {
    display: inline-block;
    border-right: 1px solid #ccc;
    padding-top: .3125rem;
    padding-right: 1rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.nav-lx {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0
}

.nav-lx,
.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none
}

.pagination {
    font-family: 'dosis', sans-serif;
    font-weight: 400
}

.page-link {
    display: block;
    color: #000;
    line-height: 1.25;
    position: relative;
    padding: .5rem .75rem;
    margin-left: -1px;
    background-color: transparent
}

.widget {
    padding: 1rem;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--white-color)
}

.widget,
.widget__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.widget__head {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    min-height: 30px;
    margin-bottom: 1rem
}

.widget__head-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: flex-first;
    align-content: flex-first
}

.widget__head-title {
    color: var(--widget-head-color);
    margin-bottom: 0
}

.widget__head-toolbar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: end;
    align-content: flex-end
}

.widget__body,
.widget__head-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.widget__body {
    color: var(--widget-body-color);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.d-inline-block {
    display: inline-block !important
}

.align-top {
    vertical-align: top !important
}

.shadow {
    transition: box-shadow .7s ease;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: var(--primary-color-hover)
}

.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
    background-color: var(--secondary-color-hover)
}

.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover {
    background-color: var(--success-color-hover)
}

.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover {
    background-color: var(--danger-color-hover)
}

.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover {
    background-color: var(--warning-color-hover)
}

.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover {
    background-color: var(--info-color-hover)
}

.btn-light.focus,
.btn-light:active,
.btn-light:focus,
.btn-light:hover {
    background-color: var(--light-color-hover)
}

.btn-dark.focus,
.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
    background-color: var(--dark-color-hover)
}

.btn-link.focus,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline
}

.btn-outline-primary.focus,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
    color: #fff;
    background-color: var(--primary-color)
}

.btn-outline-secondary.focus,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
    color: #fff;
    background-color: var(--secondary-color)
}

.btn-outline-success.focus,
.btn-outline-success:active,
.btn-outline-success:focus,
.btn-outline-success:hover {
    color: #fff;
    background-color: var(--success-color)
}

.btn-outline-danger.focus,
.btn-outline-danger:active,
.btn-outline-danger:focus,
.btn-outline-danger:hover {
    color: #fff;
    background-color: var(--danger-color)
}

.btn-outline-warning.focus,
.btn-outline-warning:active,
.btn-outline-warning:focus,
.btn-outline-warning:hover {
    color: #333;
    background-color: var(--warning-color)
}

.btn-outline-info.focus,
.btn-outline-info:active,
.btn-outline-info:focus,
.btn-outline-info:hover {
    color: #fff;
    background-color: var(--info-color)
}

.btn-outline-light.focus,
.btn-outline-light:active,
.btn-outline-light:focus,
.btn-outline-light:hover {
    color: #333;
    background-color: var(--light-color)
}

.btn-outline-dark.focus,
.btn-outline-dark:active,
.btn-outline-dark:focus,
.btn-outline-dark:hover {
    color: #fff;
    background-color: var(--dark-color)
}

.btn-small {
    font-size: .875rem;
    padding: .25rem .5rem
}

.btn-lg {
    font-size: 1.25rem;
    padding: .5rem 1rem
}

.form-control.is-invalid {
    border-color: var(--danger-color)
}

.invalid-feedback {
    color: var(--danger-color);
    font-size: .875rem;
    width: 100%;
    margin-top: .25rem
}

input::placeholder {
    color: #000
}

.form-control:focus {
    border-color: var(--primary-color);
    outline: 0
}

.form-control:disabled,
.form-control[readonly] {
    border: 1px solid #e0e0e0
}

.page-item.active .page-link {
    font-family: 'dosis', sans-serif;
    font-weight: 700;
    border-color: transparent;
    z-index: 1;
    background-color: transparent
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    color: var(--primary-color);
    font-size: .875rem
}

.custom-range::-webkit-slider-thumb,
.custom-range::-webkit-slider-thumb:active {
    background-color: #5b2e90;
    background-color: var(--primary-color)
}

.custom-range::-webkit-slider-thumb:active {
    opacity: .25
}